<template>
    <vue-easy-lightbox :moveDisabled='true' :visible="visible" :imgs="lightboxImg" :index="index" @hide="showImg(false,'','')">
        <template v-if="Array.isArray(lightboxImg)" v-slot:prev-btn="{ prev }">
            <div @click="prev" class="btn__prev">
                <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                <title>arrowLeft</title>
                <path d="M16.3,6.3L10.8,12l5.5,5.6c0.6,0.6,0.6,1.4,0,2l0,0c-0.6,0.6-1.4,0.6-2,0L7.7,13c-0.6-0.6-0.6-1.4,0-2l6.6-6.6
            c0.6-0.6,1.4-0.6,2,0l0,0C16.9,4.9,16.9,5.8,16.3,6.3z"/>
                </svg>
            </div>
        </template>
        <template v-if="Array.isArray(lightboxImg)" v-slot:next-btn="{ next }">
            <div @click="next" class="btn__next">
                <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                <title>arrowRight</title>
                <path d="M8,17.6l5.5-5.6L8,6.4c-0.6-0.6-0.6-1.4,0-2l0,0c0.6-0.6,1.4-0.6,2,0l6.6,6.6c0.6,0.6,0.6,1.4,0,2L10,19.5
            c-0.6,0.6-1.4,0.6-2,0l0,0C7.4,19,7.4,18.2,8,17.6z"/>
                </svg>
            </div>
        </template>
        <template v-slot:close-btn="{ close }">
            <button @click="close" class="close position-absolute m-0 p-0"></button>
        </template>
    </vue-easy-lightbox>

    <div class="outstanding row justify-content-center">
        <!-- 返回頂部 -->
        <div class="goTop">
            <img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
        </div> 

        <!-- [Left] fixd logo  -->
        <transition name="fade">
            <div v-show="$route.name!='index' && delay" class="page_kv_fixed">
            <img src="@/assets/images/img_kv_head_page.png" class="w-100" alt="">
            </div>
        </transition>

        <!-- Filter -->
        <div class="subNav col-xl-10 flex-column flex-sm-row justify-content-between mb-3 mb-md-3 mt-md-1" v-show="!isPagesLoading" ref="clickaway">
            <div class="subNav__left d-flex text-white order-12 order-sm-1">
                <!-- select 1 -->
                <ul class="list-unstyled text-white mb-0">
                    <li @click="activeFilter == 'F1' ? showFilter('') : showFilter('F1')">
                        <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="(activeFilter == 'F1' ? 'dropdown--active' : '')">
                            <span>{{defaultItem}}</span>
                            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
                            </svg>
                        </button>
                    </li>

                    <transition name="toggle">
                        <li v-if="activeFilter == 'F1'" :class="(activeFilter == 'F1' ? 'active' : '')" class="dropdown__item">
                            <ul class="list-unstyled">
                                <li @click="defaultItem = '全部';showFilter('');ReadData();">全部</li>
                                <li @click="defaultItem = '攝影師';showFilter('')">攝影師</li>
                                <li @click="defaultItem = '景點';showFilter('')">景點</li>
                            </ul>
                        </li>
                    </transition>
                </ul>

                <!-- select 2 -->
                <ul v-if="defaultItem != '全部'" class="list-unstyled text-white mb-0 ml-2">
                    <li @click="activeFilter == 'F2' ? showFilter('') : showFilter('F2')">
                        <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="(activeFilter == 'F2' ? 'dropdown--active' : '')">
                            <span v-if="SctStatus==''" class="disabled">選擇{{defaultItem}}</span>
                            <span v-if="SctStatus!=''" class="disabled">{{SctStatus}}</span>
                            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
                            </svg>
                        </button>
                    </li>

                    <transition name="toggle">
                        <li v-if="activeFilter == 'F2'" :class="(activeFilter == 'F2' ? 'active' : '')" class="dropdown__item">
                            <ul class="list-unstyled" v-if="defaultItem=='攝影師'">
                                <li v-for="(item, index) in nameList" :key="index" @click="ReadData(defaultItem, item.Name);showFilter('');isPagesLoading=!isPagesLoading">{{item.Name}}</li>
                            </ul>
                            <ul class="list-unstyled" v-if="defaultItem=='景點'">
                                <li v-for="(item, index) in attractionList" :key="index" @click="ReadData(defaultItem, item.AttractionName);showFilter('');isPagesLoading=!isPagesLoading">{{item.AttractionName}}</li>
                            </ul>
                        </li>
                    </transition>
                </ul>
            </div>
            <router-link to="/intro#part2" class="subNav__right font-weight-bold btn btn-secondary mb-2 mb-sm-0 order-1 order-sm-12" role="button" aria-pressed="true">參賽辦法
			</router-link>
        </div>

        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>

        <!-- card -->
        <div v-if="!isPagesLoading" class="col-xl-10" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
            <ul class="row list-unstyled">               
                <li v-for="(item, index) in Collection" :key="index" class="col-md-6 col-lg-4">
                    <div class="card mb-4 text-center">
                        <!-- photo -->
                        <div class="card__photo mb-3">
                            <carousel v-if="item.SIMG.length>0">
                                <slide v-for="(photo,indexs) in item.SIMG" :key="indexs" @click="showImg(true,indexs,item.SIMG)">
                                    <img loading="lazy" :src="photo.Img">
                                </slide>
                                <template #addons>
                                    <navigation />
                                    <pagination class="mb-0 pl-0"/>
                                </template>
                            </carousel>
                            <span v-else>
                                <!-- <img loading="lazy" :src="item.Img" @click="showImg(true,index,item.Img)"> -->
                                <img v-lazy="{src: item.Img, loading: LoadingImg(item.Img), error:'http://hilongjw.github.io/vue-lazyload/dist/404.jpg'}" @click="showImg(true,index,item.Img)">
                            </span>
                        </div>
                        <!-- description -->
                        <p class="h6 font-weight-bold mb-1">{{item.Title}}</p>
                        <p class="h6 mb-0">{{item.Name}}</p>
                    </div>
                </li>
            </ul>
        </div>   

    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import PagesLoading from '@/components/PagesLoading.vue'
import $ from 'jquery';
import 'vue3-carousel/dist/carousel.css';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        PagesLoading,
    },
    data() {
        return {
            delay:false, 
            activeCollection:'',
            Collection: null,
            CollectionCfg:null,
            CollectionInfo:null,
            defaultItem: '全部',
            isPagesLoading: true,
            activeFilter: '',
            visible: false,
            index: 0,
            lightboxImg: [],
            nameList:[],
            attractionList:[],
            SctStatus:''
        }
    },
    setup() {
        document.title = "2021淡水八景4.0-絕景攝影邀請賽";
        document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-絕景攝影邀請賽');
        document.querySelector('meta[name="description"]').setAttribute("content", '淡水八景聞名遐邇，趕快拿起你的手機、相機，把這個世紀的美景記錄下來，淡水絕景攝影邀請賽，邀你紀錄屬於我們的美麗記憶。');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '淡水八景聞名遐邇，趕快拿起你的手機、相機，把這個世紀的美景記錄下來，淡水絕景攝影邀請賽，邀你紀錄屬於我們的美麗記憶。');
        
    },
    created() {
        this.ReadData();
    },
    mounted() {
        window.addEventListener('click', this.handleClickaway);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.handleClickaway);
    },
    updated(){
        $(window).scroll(function(){
        if($(window).scrollTop()>=1000){
            $(".goTop").fadeIn(300).click(function() {
                $('html, body').stop(true, false).animate({
                    scrollTop: 0
                }, 'slow');
                return false;
            });
        } else{
            $(".goTop").fadeOut(300);
        }
        });
    },
    methods:{
        showFilter: function(enjoyClass){
            this.activeFilter = enjoyClass;
            this.SctStatus='';
        },
        // 載入資料
        ReadData:function(GetClass=null,GetData=null){
            var FD = new FormData();
            FD.append('mode', 'sct');
            FD.append('data', '{"class":"Collection","lang":"zh-TW","SIMG":true,"sw":"1"}');

            this.axios({
                method: 'post',
                url: 'RApi',
                data: FD,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                if(GetData==null){
                    this.Collection=response.data.data.sort(function(){return 0.5 - Math.random()});
                }else{
                    if(GetClass=='攝影師'){
                        this.Collection=response.data.data.filter(item => item.Name==GetData).sort(function(){return 0.5 - Math.random()});
                        this.SctStatus=GetData;
                    }
                    if(GetClass=='景點'){
                        this.Collection=response.data.data.filter(item => item.AttractionName==GetData).sort(function(){return 0.5 - Math.random()});
                        this.SctStatus=GetData;
                    }
                }

                this.CollectionCfg=response.data.Ranking;

                // 攝影師&景點下拉選單 篩選.排序
                this.nameList = response.data.data.filter((v,i,a)=>a.findIndex(t=>(t["Name"] === v["Name"]))===i).sort((C1,C2)=>C1["Name"].localeCompare(C2["Name"]));

                this.attractionList = response.data.data.filter((v,i,a)=>a.findIndex(t=>(t["AttractionName"] === v["AttractionName"]))===i).sort((C1,C2)=>C1["AttractionName"].localeCompare(C2["AttractionName"]));

                this.isPagesLoading = false;
                this.delay=true;
            })
            .catch(function (error) {
                console.log(error);
            });
        }, 
        LoadingImg: function(Str=null){
            var StrA=Str.split("/");
            var Url='';
            StrA.forEach(function(item,index){if(index<StrA.length-1){Url=Url+item+'/';}});
            return Url+'min/'+StrA[StrA.length-1].split(".")[0]+'.'+StrA[StrA.length-1].split(".")[1];
        },
        // 燈箱 lightbox
        showImg:function(Box,index,Item) {
            if(Box!=false){
                this.index = index;
                this.lightboxImg = [];
                if(Array.isArray(Item)){
                    Item.forEach((Items) => this.lightboxImg.push(Items.Img));
                } else{
                    this.lightboxImg = Item;
                }
                document.body.classList.add("modal-open");
                this.visible = true;
            }else{
                document.body.classList.remove("modal-open");
                this.visible = false;
            }
        },
        handleHide() {
            this.visible = false;
            document.body.classList.remove("modal-open");
        },
        handleClickaway(e) {
            // console.log(e.target);
			const { clickaway } = this.$refs;
			if(!clickaway.contains(e.target)){
				this.activeFilter = '';
			}
        }
    }
}
</script>